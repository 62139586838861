<template>
	<div class="game_list">
		<el-card class="box-card">
			<el-form :inline="true" :model="formData" ref='loginRef' @keydown.enter.native="searchGameHandle">
				<el-form-item prop="gameName" label="游戏名称 :">
					<el-input v-model="formData.gameName" placeholder="请输入游戏名称"></el-input>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="searchGameHandle">搜索</el-button>
				</el-form-item>
			</el-form>
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>新增</el-button>
			<!-- 列表页 -->
			<el-table :data="gameList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="gameId" label="游戏ID" width="100">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="150">
				</el-table-column>
				<el-table-column prop="prizeType" label="奖品类型" width="130">
				</el-table-column>
				<el-table-column prop="prizeName" label="奖品名称" width="130">
				</el-table-column>
				<el-table-column prop="prizeNum" label="奖品数量" width="130">
				</el-table-column>
				<el-table-column prop="winningProbability" label="中奖几率" width="130">
				</el-table-column>
				<el-table-column prop="thresholdMoney" label="抽奖阈值" width="130">
				</el-table-column>
				<el-table-column prop="cdkCode" label="礼包码" width="130">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 项目翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>

		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" :rules="caseRules" ref='createRef' class="createGame">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">新增抽奖游戏</div>
			</template>
			<el-form :model="lotteryGame" label-width="80px" size='mini' :rules="caseRules" ref='createRef'>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="lotteryGame.gameId"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="lotteryGame.gameName"></el-input>
				</el-form-item>
				<el-form-item prop="prizeType" label="奖品类型">
					<el-select v-model="lotteryGame.prizeType" placeholder="请选择奖品类型">
						<el-option
						v-for="item in prizeTypeList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="奖品名称" prop="prizeName">
					<el-input v-model="lotteryGame.prizeName"></el-input>
				</el-form-item>
				<el-form-item label="奖品数量" prop="prizeNum">
					<el-input v-model="lotteryGame.prizeNum"></el-input>
				</el-form-item>
				<el-form-item label="奖品几率" prop="winningProbability">
					<el-input v-model="lotteryGame.winningProbability"></el-input>
				</el-form-item>
				<el-form-item label="抽奖阈值" prop="thresholdMoney">
					<el-input v-model="lotteryGame.thresholdMoney"></el-input>
				</el-form-item>
				<el-form-item label="礼包码" prop="cdkCode">
					<textarea v-model.trim="lotteryGame.cdkCode" style="width: 250px; height: 80px;"></textarea>
					<p style="color: red;">注意：每行一个礼包码，请保证礼包码输入正确</p>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: -25px;">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>

		<!-- 编辑弹框 -->
		<el-dialog title="修改" :visible.sync="dialogEdit" class="updateLotteryGame">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">修改抽奖游戏</div>
			</template>
			<el-form :model="editLotteryGame" label-width="80px" size='mini' :rules="caseRules" ref='updateRef'>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="editLotteryGame.gameId" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="editLotteryGame.gameName" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item prop="prizeType" label="奖品类型">
					<el-select v-model="editLotteryGame.prizeType" placeholder="请选择奖品类型" autocomplete="off" :disabled="true">
						<el-option
						v-for="item in prizeTypeList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="奖品名称" prop="prizeName">
					<el-input v-model="editLotteryGame.prizeName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="奖品数量" prop="prizeNum">
					<el-input v-model="editLotteryGame.prizeNum" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="奖品几率" prop="winningProbability">
					<el-input v-model="editLotteryGame.winningProbability" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="抽奖阈值" prop="thresholdMoney">
					<el-input v-model="editLotteryGame.thresholdMoney" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="礼包码" prop="cdkCode">
					<textarea v-model.trim="editLotteryGame.cdkCode" autocomplete="off" style="width: 250px; height: 80px;"></textarea>
					<p style="color: red;">注意：每行一个礼包码，请保证礼包码输入正确</p>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: -25px;">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				prizeTypeList: ['平台币','游戏币','现金红包','实物','CDK(礼包)','谢谢参与'],
				gameList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				formData: {
					gameName: ''
				},
				//创建
				dialogCreate: false,
				dialogEdit: false,
				editLotteryGame: {},
				lotteryGame: {
					gameId: '',
					gameName: '',
					prizeType: '',
					prizeName: '',
					prizeNum: '',
					winningProbability: '',
					thresholdMoney: '',
					cdkCode: ''
				},
				// 校验规则
				caseRules: {
					gameId: [{
						required: true,
						message: '游戏ID不能为空',
						trigger: 'blur'
					}],
					gameName: [{
						required: true,
						message: '游戏名称不能为空',
						trigger: 'blur'
					}],
					gamePlate: [{
						required: true,
						message: '游戏平台不能为空',
						trigger: 'blur'
					}]
				}
 
			}
		},
		methods: {
			close() {
				this.dialogCreate = false
				this.lotteryGame.gameId = ''
				this.lotteryGame.gameName = ''
				this.lotteryGame.prizeType = ''
				this.lotteryGame.prizeName = ''
				this.lotteryGame.prizeNum = ''
				this.lotteryGame.winningProbability = ''
				this.lotteryGame.thresholdMoney = ''
				this.lotteryGame.cdkCode = ''
			},
			searchGameHandle() {
				this.getList()
			},
			create() {
				manageApi.addLotteryGame(this.lotteryGame).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogCreate = false
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.editLotteryGame = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updateLotteryGame(this.editLotteryGame).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			async proDelete(id) {
				console.log('当前删除的数据id为：', id)
				manageApi.deleteLotteryGame(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listLotteryGame(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.gameList = res.data.lotteryGamePage.records
						// 保存数据总数
						this.count = res.data.lotteryGamePage.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.createGame {
	width: 60%;
	margin: 0 auto;
}
.updateLotteryGame {
	width: 60%;
	margin: 0 auto;
}
</style>