<template>
	<div class="scroll_message_list">
		<el-card class="box-card">
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>新增</el-button>
			<!-- 列表页 -->
			<el-table :data="scrollMessageList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="scrollMessage" label="消息" width="300">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" ref='createRef' class="createScrollMessage">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">新增消息</div>
			</template>
			<el-form :model="newScrollMessage" label-width="100px" size='mini' ref='createRef'>
				<el-form-item label="消息" prop="scrollMessage">
					<el-input type="textarea" v-model="newScrollMessage.scrollMessage"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				scrollMessageList: [],
				//创建
				dialogCreate: false,
				newScrollMessage: {
					scrollMessage: ''
				}
			}
		},
		methods: {
			close() {
				this.dialogCreate = false
				this.newScrollMessage.scrollMessage = ''
			},
			create() {
				manageApi.addScrollMessage(this.newScrollMessage).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogCreate = false
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			async proDelete(id) {
				console.log('当前删除的数据id为：', id)
				manageApi.deleteScrollMessage(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listScrollMessage().then((res) => {
					if (res.code === 200) {
						this.scrollMessageList = res.data.scrollMessageList
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.createScrollMessage {
	width: 60%;
	margin: 0 auto;
}
</style>