<template>
	<div class="adminAccout_list">

		<el-card class="box-card">
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>创建管理账号</el-button>
			<!-- 列表页 -->
			<el-table :data="adminAccountList" border style="width: 80%;margin-bottom: 10px;">
				<el-table-column prop="adminName" label="管理账号" width="300">
				</el-table-column>
				<el-table-column prop="adminPassword" label="密码" width="300">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改密码</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
 
		<!-- 编辑弹框 -->
		<el-dialog title="修改密码" :visible.sync="dialogEdit" class="updatePassword">
			<el-form :model="editAdminAccount" :rules="caseRules" ref='updateRef' label-width="80px">
				<el-form-item label="账号" prop="adminName">
					<el-input v-model="editAdminAccount.adminName" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="adminPassword">
					<el-input v-model="editAdminAccount.adminPassword" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" :rules="caseRules" ref='createRef' class="createAdmin">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">创建管理账号</div>
			</template>
			<el-form :model="newAdminAccount" label-width="80px" size='mini' :rules="caseRules" ref='createRef'>
				<el-form-item label="账号" prop="adminName">
					<el-input v-model="newAdminAccount.adminName"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="adminPassword">
					<el-input v-model="newAdminAccount.adminPassword" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				adminAccountList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				//编辑窗口显示
				dialogEdit: false,
				editAdminAccount: {},
				//创建
				dialogCreate: false,
				newAdminAccount: {
					adminName: '',
					adminPassword: ''
				},
				// 校验规则
				caseRules: {
					adminName: [{
						required: true,
						message: '账号不能为空',
						trigger: 'blur'
					}],
					adminPassword: [{
						required: true,
						message: '密码不能为空',
						trigger: 'blur'
					}]
				}
 
			}
		},
		methods: {
			close() {
				this.dialogCreate = false
				this.newAdminAccount.adminName = ''
				this.newAdminAccount.adminPassword = ''
			},
			create() {
				manageApi.addAdminAccount(this.newAdminAccount).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.editAdminAccount = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updateAdminAccount(this.editAdminAccount).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
 

			async proDelete(id) {
				console.log('当前删除的数据id为：', id)
				manageApi.deleteAdminAccount(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listAdminAccount().then((res) => {
					if (res.code === 200) {
						this.adminAccountList = res.data.adminAccountList
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.updatePassword {
	width: 60%;
	margin: 0 auto;
}
.createAdmin {
	width: 60%;
	margin: 0 auto;
}
</style>