<template>
  <div id="app">

    <router-view></router-view>

  </div>
</template>

<script>

import './assets/css/reset.css'
import './assets/css/theme.css'
import './assets/css/global.css'

export default {
  name: 'App',
  mounted () {
    // 根据不同路由跳转不同页面
    console.log('path',this.$router.currentRoute.path)
    let path = this.$router.currentRoute.path;
    if ('/recharge' === path || '/rechargeMobile' === path) {
      if (this._isMobile()) {
      console.log('手机端')
      this.$router.replace('/rechargeMobile')
    } else {
      console.log('pc端')
      this.$router.replace('/recharge')
    }
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>