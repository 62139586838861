// axios 发送ajax请求
import request from '@/utils/request'

export default{

  //Native下单
  nativePay(data) {
    return request({
      url: '/api/wx-pay/native',
      method: 'post',
      data
    })
  },
    //Native下单(v2)
    nativePayV2(data) {
      return request({
        url: '/api/wx-pay-v2/native',
        method: 'post',
        data
      })
    },
    
    getOpenId(code) {
      return request({
        url: '/api/wx-pay-v2/getOpenId/'+code,
        method: 'get'
      })
    }
}
