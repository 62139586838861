<template>
	<div class="rank_list">
		<div class="close" @click="closeHandle">x</div>
		<div class="rank_red">
			榜单
		</div>
		<!-- <div class="change_page">
			<el-button key="btn" type="primary" @click.native.prevent="getWeek">本周</el-button>
			<el-button key="btn" type="primary" @click.native.prevent="getHistory">历史</el-button>
		</div> -->
		<div class="wrap">
			<!-- 列表页 -->
			<span class="title" style="color: yellow; font-size: 20px; margin-bottom: 5px;margin-left: -290px;">{{title}}排行榜:</span>
			<el-table :data="rankList"  style="width: 100%; margin-bottom: 10px;">
				<el-table-column prop="index" label="排名" width="50" align="center">
				</el-table-column>
				<el-table-column prop="userName" label="玩家账号" width="130">
				</el-table-column>
				<el-table-column prop="total" label="红包个数" width="80" align="center">
				</el-table-column>
				<el-table-column prop="money" label="现金" width="70" align="center">
				</el-table-column>
				<el-table-column prop="gamebi" label="平台币" width="70" align="center">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		name: "rank",
		data() {
			return {
				rankList: [],
				type: 0,
				gameId: '',
				title: '本周'
			}
		},
		methods: {
			closeHandle() {
				this.$router.push({
					name: "redPackageYu",
				});
			},
			// getWeek() {
			// 	alert("本周")
			// 	this.type = 0;
			// 	this.title = '本周';
			// 	this.getList(this.gameId)
			// },
			// getHistory() {
			// 	alert("历史")
			// 	this.type = 1;
			// 	this.title = '历史';
			// 	this.getList(this.gameId)
			// },
			// 获取列表
			async getList(gameId) {
				manageApi.getRedPackageRecord(gameId,this.type).then((res) => {
					if (res.code === 200) {
						this.rankList = res.data.rankList
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			}
		},
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			var gameId = this.$route.params.gameId;
			this.type = this.$route.params.type;
			if (this.type === 0) {
				this.title = '本周';
			} else {
				this.title = '历史';
			}
			if (gameId == null || gameId == undefined) {
				this.$message({
					message: '请选择游戏',
					type: 'error',
					duration: 1000
				});
				return
			}
			this.gameId = gameId;
			this.getList(gameId);
		}
 
	}
</script>
 
<style scoped>
.close {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  font-weight: bolder;
}
.rank_red {
  color: #4cc82d;
  position: absolute;
  top: 20px;
  right: 170px;
  font-size: 30px;
  font-weight: bolder;
}
.change_page {
  color: #4cc82d;
  position: absolute;
  top: 50px;
  right: 130px;
  font-size: 30px;
  font-weight: bolder;
}
.wrap {
	position: absolute;
	top: 40px;
}

</style>