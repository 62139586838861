<template>
	<div class="red_package_base">
		<el-card class="box-card">
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>新增</el-button>
			<!-- 列表页 -->
			<el-table :data="redPackageBaseList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="redPackageNum" label="红包金额范围" width="150">
				</el-table-column>
				<el-table-column prop="redPackageProbability" label="红包中奖几率" width="150">
				</el-table-column>
				<el-table-column prop="gameCoinNum" label="游戏币个数范围" width="150">
				</el-table-column>
				<el-table-column prop="gameCoinProbability" label="游戏币中奖几率" width="150">
				</el-table-column>
				<el-table-column prop="startTime" label="红包雨开始时间" width="150">
				</el-table-column>
				<el-table-column prop="time" label="红包雨持续时间(秒)" width="150">
				</el-table-column>
				<el-table-column prop="maxTimes" label="每周最多可获取的抽奖次数" width="150">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 项目翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>

		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" ref='createRef' class="createRedPackageBase">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">新增红包雨基础配置</div>
			</template>
			<el-form :model="newRedPackageBase" label-width="80px" size='mini' :rules="caseRules" ref='createRef'>
				<el-form-item label="红包金额范围" prop="redPackageNum">
					<el-input v-model="newRedPackageBase.redPackageNum"></el-input>
				</el-form-item>
				<el-form-item label="红包中奖几率" prop="redPackageProbability">
					<el-input v-model="newRedPackageBase.redPackageProbability"></el-input>
				</el-form-item>
				<el-form-item label="游戏币个数范围" prop="gameCoinNum">
					<el-input v-model="newRedPackageBase.gameCoinNum"></el-input>
				</el-form-item>
				<el-form-item label="游戏币中奖几率" prop="gameCoinProbability">
					<el-input v-model="newRedPackageBase.gameCoinProbability"></el-input>
				</el-form-item>
				<el-form-item label="红包雨开始时间" prop="startTime">
					<el-input v-model="newRedPackageBase.startTime"></el-input>
				</el-form-item>
				<el-form-item label="红包雨持续时间(秒)" prop="time">
					<el-input v-model="newRedPackageBase.time"></el-input>
				</el-form-item>
				<el-form-item label="每周最多可获取的抽奖次数" prop="maxTimes">
					<el-input v-model="newRedPackageBase.maxTimes"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>

		<!-- 编辑弹框 -->
		<el-dialog title="修改" :visible.sync="dialogEdit" class="updateRedPackageBase">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">修改红包雨基础配置</div>
			</template>
			<el-form :model="redPackageBase" label-width="80px" size='mini' :rules="caseRules" ref='updateRef'>
				<el-form-item label="红包金额范围" prop="redPackageNum">
					<el-input v-model="redPackageBase.redPackageNum" autocomplete="off" ></el-input>
				</el-form-item>
				<el-form-item label="红包中奖几率" prop="redPackageProbability">
					<el-input v-model="redPackageBase.redPackageProbability" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="游戏币个数范围" prop="gameCoinNum">
					<el-input v-model="redPackageBase.gameCoinNum" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="游戏币中奖几率" prop="gameCoinProbability">
					<el-input v-model="redPackageBase.gameCoinProbability" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="红包雨开始时间" prop="startTime">
					<el-input v-model="redPackageBase.startTime" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="红包雨持续时间(秒)" prop="time">
					<el-input v-model="redPackageBase.time" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="每周最多可获取的抽奖次数" prop="maxTimes">
					<el-input v-model="redPackageBase.maxTimes" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: -25px;">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				redPackageBaseList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				formData: {
				},
				//创建
				dialogCreate: false,
				dialogEdit: false,
				redPackageBase: {},
				newRedPackageBase: {
					redPackageNum: '',
					redPackageProbability: '',
					gameCoinNum: '',
					gameCoinProbability: '',
					startTime: '',
					time: '',
					maxTimes: ''
				}
			}
		},

		methods: {
			close() {
				this.dialogCreate = false
				this.newRedPackageBase.redPackageNum = ''
				this.newRedPackageBase.redPackageProbability = ''
				this.newRedPackageBase.gameCoinNum = ''
				this.newRedPackageBase.gameCoinProbability = ''
				this.newRedPackageBase.startTime = ''
				this.newRedPackageBase.time = ''
				this.newRedPackageBase.maxTimes = ''
			},
			create() {
				manageApi.addRedPackageBase(this.newRedPackageBase).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogCreate = false
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.redPackageBase = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updateRedPackageBase(this.redPackageBase).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			async proDelete(id) {
				console.log('当前删除的数据id为:', id)
				manageApi.deleteRedPackageBase(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listRedPackageBase(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.redPackageBaseList = res.data.redPackageBasePage.records
						// 保存数据总数
						this.count = res.data.redPackageBasePage.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.createRedPackageBase {
	width: 60%;
	margin: 0 auto;
}
.updateRedPackageBase {
	width: 60%;
	margin: 0 auto;
}
</style>