<template>
	<div class="red_package_game">
		<el-card class="box-card">
			<el-form :inline="true" :model="formData" ref='loginRef' @keydown.enter.native="searchGameHandle">
				<el-form-item prop="gameName" label="游戏名称 :">
					<el-input v-model="formData.gameName" placeholder="请输入游戏名称"></el-input>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="searchGameHandle">搜索</el-button>
					<el-button type="primary" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>新增</el-button>
			<!-- 列表页 -->
			<el-table :data="redPackageGameList"  style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="gameId" label="游戏ID" width="150">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="150">
				</el-table-column>
				<el-table-column prop="minMoney" label="本周最小充值金额" width="150">
				</el-table-column>
				<el-table-column prop="minTimes" label="本周最小充值金额领取的抽奖次数" width="150">
				</el-table-column>
				<el-table-column prop="midMoney" label="本周第二充值金额" width="150">
				</el-table-column>
				<el-table-column prop="midTimes" label="本周第二充值金额领取的抽奖次数" width="150">
				</el-table-column>
				<el-table-column prop="maxMoney" label="本周最大充值金额" width="150">
				</el-table-column>
				<el-table-column prop="maxTimes" label="本周最大充值金额领取的抽奖次数" width="150">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 项目翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>

		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" ref='createRef' class="createRedPackageGame">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">新增红包雨基础配置</div>
			</template>
			<el-form :model="newRedPackageGame" label-width="80px" size='mini' ref='createRef'>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="newRedPackageGame.gameId"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="newRedPackageGame.gameName"></el-input>
				</el-form-item>
				<el-form-item label="本周最小充值金额" prop="minMoney">
					<el-input v-model="newRedPackageGame.minMoney"></el-input>
				</el-form-item>
				<el-form-item label="本周最小充值金额领取的抽奖次数" prop="minTimes">
					<el-input v-model="newRedPackageGame.minTimes"></el-input>
				</el-form-item>
				<el-form-item label="本周第二充值金额" prop="midMoney">
					<el-input v-model="newRedPackageGame.midMoney"></el-input>
				</el-form-item>
				<el-form-item label="本周第二充值金额领取的抽奖次数" prop="midTimes">
					<el-input v-model="newRedPackageGame.midTimes"></el-input>
				</el-form-item>
				<el-form-item label="本周最大充值金额" prop="maxMoney">
					<el-input v-model="newRedPackageGame.maxMoney"></el-input>
				</el-form-item>
				<el-form-item label="本周最大充值金额领取的抽奖次数" prop="maxTimes">
					<el-input v-model="newRedPackageGame.maxTimes"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>

		<!-- 编辑弹框 -->
		<el-dialog title="修改" :visible.sync="dialogEdit" class="updateRedPackageGame">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">修改红包雨基础配置</div>
			</template>
			<el-form :model="redPackageGame" label-width="80px" size='mini' :rules="caseRules" ref='updateRef'>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="redPackageGame.gameId" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="redPackageGame.gameName" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="本周最小充值金额" prop="minMoney">
					<el-input v-model="redPackageGame.minMoney" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="本周最小充值金额领取的抽奖次数" prop="minTimes">
					<el-input v-model="redPackageGame.minTimes" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="本周第二充值金额" prop="midMoney">
					<el-input v-model="redPackageGame.midMoney" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="本周第二充值金额领取的抽奖次数" prop="midTimes">
					<el-input v-model="redPackageGame.midTimes" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="本周最大充值金额" prop="maxMoney">
					<el-input v-model="redPackageGame.maxMoney" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="本周最大充值金额领取的抽奖次数" prop="maxTimes">
					<el-input v-model="redPackageGame.maxTimes" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: -25px;">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				redPackageGameList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				formData: {
					gameName: ''
				},
				//创建
				dialogCreate: false,
				dialogEdit: false,
				redPackageGame: {},
				newRedPackageGame: {
					gameId: '',
					gameName: '',
					minMoney: '',
					minTimes: '',
					midMoney: '',
					midTimes: '',
					maxMoney: '',
					maxTimes: ''
				}
			}
		},

		methods: {
			reset(){
				this.formData.gameName = ''
			},
			close() {
				this.dialogCreate = false
				this.newRedPackageGame.gameId = ''
				this.newRedPackageGame.gameName = ''
				this.newRedPackageGame.minMoney = ''
				this.newRedPackageGame.minTimes = ''
				this.newRedPackageGame.midMoney = ''
				this.newRedPackageGame.midTimes = ''
				this.newRedPackageGame.maxMoney = ''
				this.newRedPackageGame.maxTimes = ''
			},
			searchGameHandle() {
				this.getList()
			},
			create() {
				manageApi.addRedPackageGame(this.newRedPackageGame).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogCreate = false
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.redPackageGame = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updateRedPackageGame(this.redPackageGame).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			async proDelete(id) {
				console.log('当前删除的数据id为:', id)
				manageApi.deleteRedPackageGame(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listRedPackageGame(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.redPackageGameList = res.data.redPackageGamePage.records
						// 保存数据总数
						this.count = res.data.redPackageGamePage.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.createRedPackageGame {
	width: 60%;
	margin: 0 auto;
}
.updateRedPackageGame {
	width: 60%;
	margin: 0 auto;
}
</style>