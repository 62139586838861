<template>
	<div class="game_list">
		<el-card class="box-card">
			<el-form :inline="true" :model="formData" ref='loginRef' @keydown.enter.native="searchGameHandle">
				<el-form-item prop="userName" label="用户账号 :">
					<el-input v-model="formData.userName" placeholder="请输入用户账号"></el-input>
				</el-form-item>
				<el-form-item prop="gameName" label="游戏名称 :">
					<el-input v-model="formData.gameName" placeholder="请输入游戏名称"></el-input>
				</el-form-item>
				<el-form-item prop="prizeName" label="奖品名称 :">
					<el-input v-model="formData.prizeName" placeholder="请输入奖品名称"></el-input>
				</el-form-item>
				<el-form-item prop="grantStatus" label="发放状态">
					<el-select v-model="formData.grantStatus" placeholder="请选择发放状态">
						<el-option
						v-for="item in grantStatus"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="searchGameHandle">搜索</el-button>
					<el-button type="primary" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
	
			<!-- 列表页 -->
			<el-table :data="prizeGrantRecordList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="userName" label="用户账号" width="100">
				</el-table-column>
				<el-table-column prop="gameId" label="游戏ID" width="100">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="100">
				</el-table-column>
				<el-table-column prop="prizeType" label="奖品类型" width="100">
				</el-table-column>
				<el-table-column prop="prizeName" label="奖品名称" width="100">
				</el-table-column>
				<el-table-column prop="prizeNum" label="奖品数量" width="100">
				</el-table-column>
				<el-table-column prop="grantStatus" label="发放状态" width="100">
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="100">
				</el-table-column>
				<el-table-column prop="realName" label="姓名" width="100">
				</el-table-column>
				<el-table-column prop="phone" label="电话" width="100">
				</el-table-column>
				<el-table-column prop="address" label="地址" width="100">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 项目翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>

		<!-- 编辑弹框 -->
		<el-dialog title="修改" :visible.sync="dialogEdit" class="updatePrizeGrantRecord">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">修改</div>
			</template>
			<el-form :model="editPrizeGrantRecord" label-width="80px" size='mini' ref='updateRef'>
				<el-form-item label="用户账号" prop="userName">
					<el-input v-model="editPrizeGrantRecord.userName" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="editPrizeGrantRecord.gameId" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="editPrizeGrantRecord.gameName" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item prop="prizeType" label="奖品类型">
					<el-select v-model="editPrizeGrantRecord.prizeType" placeholder="请选择奖品类型" autocomplete="off" :disabled="true">
						<el-option
						v-for="item in prizeTypeList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="奖品名称" prop="prizeName">
					<el-input v-model="editPrizeGrantRecord.prizeName" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="奖品数量" prop="prizeNum">
					<el-input v-model="editPrizeGrantRecord.prizeNum" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item prop="grantStatus" label="发放状态">
					<el-select v-model="editPrizeGrantRecord.grantStatus" placeholder="请选择发放状态">
						<el-option
						v-for="item in grantStatus"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="姓名" prop="realName">
					<el-input v-model="editPrizeGrantRecord.realName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="电话" prop="phone">
					<el-input v-model="editPrizeGrantRecord.phone" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="地址" prop="address">
					<el-input v-model="editPrizeGrantRecord.address" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				prizeTypeList: ['平台币','游戏币','现金红包','实物','CDK(礼包)'],
				grantStatus: ['未发放','发送中','已发放'],
				prizeGrantRecordList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				formData: {
					userName: '',
					gameName: '',
					prizeName: '',
					grantStatus: ''
				},
				
				dialogEdit: false,
				editPrizeGrantRecord: {}
 
			}
		},
		methods: {
			reset(){
				this.formData.userName = ''
				this.formData.gameName = ''
				this.formData.prizeName = ''
				this.formData.grantStatus = ''
			},
			searchGameHandle() {
				this.getList()
			},
			proEdit(value) {
				this.editPrizeGrantRecord = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updatePrizeGrantRecord(this.editPrizeGrantRecord).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listPrizeGrantRecord(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.prizeGrantRecordList = res.data.prizeGrantRecordPage.records
						// 保存数据总数
						this.count = res.data.prizeGrantRecordPage.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.updatePrizeGrantRecord {
	width: 60%;
	margin: 0 auto;
}
</style>