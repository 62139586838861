<template>
	<div class="orders_list">
		<el-card class="box-card">
			<el-form :inline="true" :model="formData" ref='loginRef' @keydown.enter.native="searchOrderHandle">
				<el-form-item prop="userName" label="用户账号 :">
					<el-input v-model="formData.userName" placeholder="请输入用户账号"></el-input>
				</el-form-item>
        		<el-form-item prop="orderNo" label="订单编号 :">
					<el-input v-model="formData.orderNo" placeholder="请输入订单编号"></el-input>
				</el-form-item>
				<el-form-item prop="time" label="日期 :">
					<el-date-picker
						value-format="yyyy-MM-dd HH:mm:ss"
						v-model="formData.startTime"
						type="datetime"
						placeholder="选择开始时间">
					</el-date-picker>
					<el-date-picker
						value-format="yyyy-MM-dd HH:mm:ss"
						v-model="formData.endTime"
						type="datetime"
						placeholder="选择结束时间">
					</el-date-picker>
				</el-form-item>
				<el-form-item prop="gameName" label="游戏名称 :">
					<el-input v-model="formData.gameName" placeholder="请输入游戏名称"></el-input>
				</el-form-item>
				<el-form-item prop="paymentType" label="支付方式">
					<el-select v-model="formData.paymentType" placeholder="请选择支付方式">
						<el-option
						v-for="item in paymentTypeList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="orderStatus" label="支付状态">
					<el-select v-model="formData.orderStatus" placeholder="请选择支付状态">
						<el-option
						v-for="item in orderStatusList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item prop="rechargeType" label="充值方式">
					<el-select v-model="formData.rechargeType" placeholder="请选择充值方式">
						<el-option
						v-for="item in rechargeTypeList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="searchOrderHandle">搜索</el-button>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<!-- 列表页 -->
			<el-table :data="ordersList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="orderNo" label="订单编号" width="180">
				</el-table-column>
				<el-table-column prop="gamePlate" label="游戏平台" width="150">
				</el-table-column>
				<el-table-column prop="gameId" label="游戏ID" width="150">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="150">
				</el-table-column>
				<el-table-column prop="userName" label="用户账号" width="150">
				</el-table-column>
				<el-table-column prop="payMoney" label="支付金额" width="100">
				</el-table-column>
				<el-table-column prop="paymentType" label="支付方式" width="100">
				</el-table-column>
				<el-table-column prop="orderStatus" label="支付状态" width="100">
				</el-table-column>
				<el-table-column prop="rechargeType" label="充值方式" width="100">
				</el-table-column>
				<el-table-column prop="discount" label="折扣" width="100">
				</el-table-column>
				<el-table-column prop="updateTime" label="操作时间" width="160">
				</el-table-column>
			</el-table>
			<!-- 翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				ordersList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				formData: {
					userName: '',
					orderNo: '',
					startTime: '',
					endTime: '',
					gameName: '',
					paymentType: '',
					orderStatus: '',
					rechargeType: ''
				},
				paymentTypeList: ['微信','支付宝'],
				orderStatusList: ['支付成功','未支付'],
				rechargeTypeList: ['首充','续充']
			}
		},
		methods: {
			reset() {
				this.formData.userName = ''
				this.formData.orderNo = ''
				this.formData.startTime = ''
				this.formData.endTime = ''
				this.formData.gameName = ''
				this.formData.paymentType = ''
				this.formData.orderStatus = ''
				this.formData.rechargeType = ''
			},
			searchOrderHandle() {
				this.getList()
			},
			// 获取列表
			async getList() {
				manageApi.listOrder(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.ordersList = res.data.orderInfoList.records
						// 保存数据总数
						this.count = res.data.orderInfoList.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
</style>