<template>
	<div class="platePassword_list">

		<el-card class="box-card">
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>创建平台登录信息</el-button>
			<!-- 列表页 -->
			<el-table :data="platePasswordList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="gamePlate" label="游戏平台" width="150">
				</el-table-column>
				<el-table-column prop="userName" label="登录账号" width="150">
				</el-table-column>
				<el-table-column prop="password" label="登录密码" width="150">
				</el-table-column>
				<el-table-column prop="paypass" label="支付密码" width="150">
				</el-table-column>
				<el-table-column prop="clientId" label="clientId" width="150">
				</el-table-column>
				<el-table-column prop="formhash" label="formhash" width="150">
				</el-table-column>
				<el-table-column prop="remark" label="说明" width="150">
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>
 
		<!-- 编辑弹框 -->
		<el-dialog title="修改" :visible.sync="dialogEdit" class="updatePassword">
			<el-form :model="editPlatePassword" ref='updateRef' label-width="80px">
				<el-form-item label="ID" prop="id" v-if="false">
					<el-input v-model="editPlatePassword.id" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏平台" prop="gamePlate">
					<el-input v-model="editPlatePassword.gamePlate" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="登录账号" prop="userName">
					<el-input v-model="editPlatePassword.userName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="登录密码" prop="password">
					<el-input v-model="editPlatePassword.password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="支付密码" prop="paypass">
					<el-input v-model="editPlatePassword.paypass" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="clientId" prop="clientId">
					<el-input v-model="editPlatePassword.clientId" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="formhash" prop="formhash">
					<el-input v-model="editPlatePassword.formhash" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="说明" prop="remark">
					<el-input v-model="editPlatePassword.remark" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate"  ref='createRef' class="createPlatePassword">
			<template slot='title'>
				<div style="text-align: center;font-size: 24px;">创建平台登录信息</div>
			</template>
			<el-form :model="newPlatePassword" label-width="80px" size='mini' :rules="caseRules" ref='createRef'>
				<el-form-item prop="gamePlate" label="游戏平台" required>
					<el-select v-model="newPlatePassword.gamePlate" placeholder="请选择游戏平台" style="width: 80%">
						<el-option
						v-for="item in gamePlateList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="登录账号" prop="userName">
					<el-input v-model="newPlatePassword.userName" autocomplete="off" style="width: 80%"></el-input>
				</el-form-item>
				<el-form-item label="登录密码" prop="password">
					<el-input v-model="newPlatePassword.password" autocomplete="off" style="width: 80%"></el-input>
				</el-form-item>
				<el-form-item label="支付密码" prop="paypass">
					<el-input v-model="newPlatePassword.paypass" autocomplete="off" style="width: 80%"></el-input>
				</el-form-item>
				<el-form-item label="clientId" prop="clientId">
					<el-input v-model="newPlatePassword.clientId" autocomplete="off" style="width: 80%"></el-input>
				</el-form-item>
				<el-form-item label="formhash" prop="formhash">
					<el-input v-model="newPlatePassword.formhash" autocomplete="off" style="width: 80%"></el-input>
				</el-form-item>
				<el-form-item label="说明" prop="remark">
					<el-input v-model="newPlatePassword.remark" autocomplete="off" style="width: 80%"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				platePasswordList: [],
				gamePlateList: [], //游戏平台列表
				//编辑窗口显示
				dialogEdit: false,
				editPlatePassword: {},
				//创建
				dialogCreate: false,
				newPlatePassword: {
					gamePlate: '',
					userName: '',
					password: '',
					paypass: '',
					clientId: '',
					formhash: '',
					remark: ''
				}
			}
		},
		//页面加载时执行
		created() {
			//获取游戏平台列表
			manageApi.listGamePlate().then((response) => {
			this.gamePlateList = response.data.gamePlateList
			})
		},
		methods: {
			close() {
				this.dialogCreate = false
				this.newPlatePassword.gamePlate = ''
				this.newPlatePassword.userName = ''
				this.newPlatePassword.password = ''
				this.newPlatePassword.paypass = ''
				this.newPlatePassword.clientId = ''
				this.newPlatePassword.formhash = ''
				this.newPlatePassword.remark = ''
			},
			create() {
				manageApi.addPlatePassword(this.newPlatePassword).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.editPlatePassword = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updatePlatePassword(this.editPlatePassword).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
 

			async proDelete(id) {
				console.log('当前删除的数据id为：', id)
				manageApi.deletePlatePassword(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listPlatePassword().then((res) => {
					if (res.code === 200) {
						this.platePasswordList = res.data.platePasswordList
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.updatePassword {
	width: 60%;
	margin: 0 auto;
}
.createAdmin {
	width: 60%;
	margin: 0 auto;
}
</style>