<template>
    <div>
		
	</div>
</template>

<script>

export default {
    created() {
        let _self = this
        this.$alert('登录信息失效', '提示', {
            confirmButtonText: '确定'
        }).then(() => {
            window.sessionStorage.removeItem('token')
            _self.$router.replace({path: '/login'})
        })
    }
}
</script>
