<template>
 
	<el-container>
		<el-header>
			<div class="title">
				后台管理系统
			</div>
			<div class="logonout">
				<el-popconfirm title="确认退出登录？" @confirm='loginout()'>
					<div slot="reference">退出登录</div>
				</el-popconfirm>
			</div>
 
		</el-header>
		<el-container>
			<!-- 侧边菜单栏 :router='true' :default-active='this.$route.path'  实现路由刷新左侧菜单栏保持选中状态-->
			<el-aside width="250px">
				<el-menu :router='true' background-color='rgb(66, 79, 98)' class="el-menu-demo" :default-active='this.$route.path'
					text-color="#fff" active-text-color="#00aaff" unique-opened>
					
					<el-submenu index="ordersManage">
						<template slot="title">
							<i class="el-icon-s-home"></i>
							<span>充值记录</span>
						</template>
						<el-menu-item index="/orders">
							<template slot="title">
								<i class="el-icon-document-copy"></i>
								<span>充值记录列表</span>
							</template>
						</el-menu-item>
					</el-submenu>
					
					<el-submenu index="gameManage">
						<template slot="title">
							<i class="el-icon-folder-opened"></i>
							<span>游戏设置</span>
						</template>
						<el-menu-item index="/game">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>游戏列表</span>
							</template>
						</el-menu-item>
					</el-submenu>
				
					<el-submenu index="discountManage">
						<template slot="title">
							<i class="el-icon-notebook-2"></i>
							<span>折扣设置</span>
						</template>
						<el-menu-item index="/userDiscount">
							<template slot="title">
								<i class="el-icon-notebook-1"></i>
								<span>用户折扣列表</span>
							</template>
							</el-menu-item>
						<el-menu-item index="/gameDiscount">
							<template slot="title">
								<i class="el-icon-notebook-1"></i>
								<span>游戏折扣列表</span>
							</template>
						</el-menu-item>
					</el-submenu>

					<el-submenu index="lotteryManage">
						<template slot="title">
							<i class="el-icon-folder-opened"></i>
							<span>抽奖设置</span>
						</template>
						<el-menu-item index="/lotteryGame">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>抽奖游戏列表</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/prizeGrantRecord">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>奖品发放记录</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/rewardContent">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>活动奖励内容</span>
							</template>
						</el-menu-item>
					</el-submenu>


					<el-submenu index="redPackageManage">
						<template slot="title">
							<i class="el-icon-folder-opened"></i>
							<span>红包雨设置</span>
						</template>
						<el-menu-item index="/redPackageBase">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>红包雨基础设置</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/redPackageGame">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>红包雨游戏列表</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/redPackageRecord">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>奖品发放记录</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/redPackageRule">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>活动规则设置</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/redPackageUser">
							<template slot="title">
								<i class="el-icon-tickets"></i>
								<span>红包雨内定玩家</span>
							</template>
						</el-menu-item>
					</el-submenu>


					<el-submenu index="adminAccountManage">
						<template slot="title">
							<i class="el-icon-notebook-2"></i>
							<span>管理员设置</span>
						</template>
						<el-menu-item index="/adminAccount">
							<template slot="title">
								<i class="el-icon-notebook-1"></i>
								<span>管理员列表</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/platePassword">
							<template slot="title">
								<i class="el-icon-notebook-1"></i>
								<span>平台登录信息</span>
							</template>
						</el-menu-item>
						<el-menu-item index="/scrollMessage">
							<template slot="title">
								<i class="el-icon-notebook-1"></i>
								<span>滚动消息设置</span>
							</template>
						</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-aside>
			<!-- 主体内容显示区域 -->
			<el-main>
				<router-view></router-view>
			</el-main>
		</el-container>
	</el-container>
</template>
 
<script>
	export default {
		data() {
			return {
				rou: true
			}
		},
		methods: {
			// 退出登录的方法
			loginout() {
				window.sessionStorage.removeItem('token')
				this.$router.push('/login')
			}
		},
 
	}
</script>
 
<style scoped>
	/* --------页面顶部的样式-------- */
	.el-header {
		background: #625fa3;
		margin-bottom: 5px;
	}
 
	.title {
		width: 90%;
		color: #fff;
		font: normal 28px/60px "microsoft yahei";
		text-align: center;
		float: left;
	}
 
	.logonout {
		width: 60px;
		color: #fff;
		font: normal 14px/60px "microsoft yahei";
		float: right;
		text-align: center;
	}
 
	.logonout:hover {
		background: #000;
	}
 
	/* ----------侧边菜单的样式---------- */
	.el-menu {
		height: 900px;
	}
</style>