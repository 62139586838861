<template>
	<div class="red_package_record_list">
		<el-card class="box-card">
			<el-form :inline="true" :model="formData" ref='loginRef' @keydown.enter.native="searchGameHandle">
				<el-form-item prop="userName" label="用户账号 :">
					<el-input v-model="formData.userName" placeholder="请输入用户账号"></el-input>
				</el-form-item>
				<el-form-item prop="gameName" label="游戏名称 :">
					<el-input v-model="formData.gameName" placeholder="请输入游戏名称"></el-input>
				</el-form-item>
				<el-form-item prop="prizeName" label="奖品名称 :">
					<el-input v-model="formData.prizeName" placeholder="请输入奖品名称"></el-input>
				</el-form-item>
				<el-form-item prop="grantStatus" label="发放状态">
					<el-select v-model="formData.grantStatus" placeholder="请选择发放状态">
						<el-option
						v-for="item in grantStatus"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="searchGameHandle">搜索</el-button>
					<el-button type="primary" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
	
			<!-- 列表页 -->
			<el-table :data="redPackageRecordList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="userName" label="用户账号" width="150">
				</el-table-column>
				<el-table-column prop="openId" label="微信OPENID" width="150">
				</el-table-column>
				<el-table-column prop="gameId" label="游戏ID" width="150">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="150">
				</el-table-column>
				<el-table-column prop="prizeName" label="奖品名称" width="150">
				</el-table-column>
				<el-table-column prop="prizeNum" label="奖品数量" width="150">
				</el-table-column>
				<el-table-column prop="grantStatus" label="发放状态" width="150">
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="200">
				</el-table-column>
			</el-table>
			<!-- 项目翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				grantStatus: ['未发放','发送中','已发放'],
				redPackageRecordList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				formData: {
					userName: '',
					gameName: '',
					prizeName: '',
					grantStatus: ''
				}
			}
		},
		methods: {
			reset(){
				this.formData.userName = ''
				this.formData.gameName = ''
				this.formData.prizeName = ''
				this.formData.grantStatus = ''
			},
			searchGameHandle() {
				this.getList()
			},
			// 获取列表
			async getList() {
				manageApi.listRedPackageRecord(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.redPackageRecordList = res.data.redPackageRecordPage.records
						// 保存数据总数
						this.count = res.data.redPackageRecordPage.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
</style>