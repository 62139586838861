import { render, staticRenderFns } from "./RedPackageRecord.vue?vue&type=template&id=421ef14c&scoped=true&"
import script from "./RedPackageRecord.vue?vue&type=script&lang=js&"
export * from "./RedPackageRecord.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421ef14c",
  null
  
)

export default component.exports