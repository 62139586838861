// 引入Vue
import Vue from 'vue'
// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入App
import App from './App'
// 引入路由器
import router from './router'

// 二维码生成器
import VueQriously from 'vue-qriously'
Vue.use(VueQriously)

// 大转盘抽奖 lucky-canvas
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)



// const express = require('express');
// const cors = require('cors');
// const app = express();
// app.use(cors());

// 关闭Vue生产提示
Vue.config.productionTip = false

ElementUI.Select.computed.readonly = function() {
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));
  return !(this.filterable || this.multiple || !isIE) && !this.visible;
}

Vue.use(ElementUI)

import VueDeviceDetector from 'vue-device-detector'

Vue.use(VueDeviceDetector)

new Vue({
  render: h => h(App), //将App组件放入容器中
  router: router
}).$mount('#app')