<template>
    <div class="bg-fa of">
      <div class="title">
        自助充值中心
     </div>
      <section id="recharge" class="container">
        <div class="notice-content" :style="{ animationDuration: duration + 's' }">
          <div v-for="(item, index) in notices" :key="index" class="notice-item">
            {{ item }}
          </div>
        </div>
        <el-form :model="payOrder" ref='rechargeRef' :rules="caseRules" label-width="100px">
          <el-form-item prop="userName" label="游戏账号 :" required>
              <el-input v-model.trim="payOrder.userName"  placeholder="请输入账号(7Li平台输入UID)" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item prop="payMoney" label="充值金额 :" required>
              <el-input v-model.trim="payOrder.payMoney" placeholder="请输入金额" style="width: 80%"></el-input>
          </el-form-item>
          <el-form-item prop="gamePlate" label="游戏平台 :" required>
            <el-select v-model="payOrder.gamePlate" placeholder="请选择游戏平台" style="width: 80%">
              <el-option
                v-for="item in gamePlateList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
            </el-form-item>
            <el-form-item prop="gameId" label="充值游戏 :" required>
              <el-select 
                  v-model="payOrder.gameId" 
                  filterable 
                  placeholder="请选择充值游戏" 
                  style="width: 80%" 
                  @click.native="queryGameByGamePlate" 
                  @hook:mounted="cancalReadOnly"
                  @visible-change="cancalReadOnly"
              >
                <el-option
                  v-for="item in gameList"
                  :key="item.gameId"
                  :label="item.gameName"
                  :value="item.gameId">
                </el-option>
              </el-select>
            </el-form-item>
          <el-radio v-model="payOrder.rechargeType" label="1">首充</el-radio>
          <el-radio v-model="payOrder.rechargeType" label="2">续充</el-radio>
              </el-form>
  
        <!-- <div class="PaymentChannel_payment-channel-panel">
          <h3 class="PaymentChannel_title">选择支付方式</h3>
          <div class="PaymentChannel_channel-options">
            选择微信 -->
            <!-- <div
              :class="[
                'ChannelOption_payment-channel-option',
                { current: payOrder.payType === 'wxpay' },
              ]"
              @click="selectPayType('wxpay')"
            >
              <div class="ChannelOption_channel-icon">
                <img src="../assets/img/wxpay.png" class="ChannelOption_icon" />
              </div>
              <div class="ChannelOption_channel-info">
                <div class="ChannelOption_channel-label">
                  <div class="ChannelOption_label">微信支付</div>
                  <div class="ChannelOption_sub-label"></div>
                  <div class="ChannelOption_check-option"></div>
                </div>
              </div>
            </div> -->
  
            <!-- 选择支付宝 -->
            <!-- <div
              :class="[
                'ChannelOption_payment-channel-option',
                { current: payOrder.payType === 'alipay' },
              ]"
              @click="selectPayType('alipay')"
            >
              <div class="ChannelOption_channel-icon">
                <img src="../assets/img/alipay.png" class="ChannelOption_icon" />
              </div>
              <div class="ChannelOption_channel-info">
                <div class="ChannelOption_channel-label">
                  <div class="ChannelOption_label">支付宝</div>
                  <div class="ChannelOption_sub-label"></div>
                  <div class="ChannelOption_check-option"></div>
                </div>
              </div>
            </div> -->
          <!-- </div>
        </div> -->
  
        <div class="payButtom">
          <el-button
            :disabled="payBtnDisabled"
            type="warning"
            round
            style="width: 180px; height: 44px; font-size: 18px"
            @click="toPay()"
          >
            确认支付
          </el-button>
        </div>
      </section>
  
      <!-- <div class="diy-dialog" v-show="diyDialogShow">
        <div style="text-align: center;font-size: 30px; margin-bottom: 20px; color: red;">提示</div>
        <van-divider/>
        <div style="height: 80px ;font-size: 15px;text-align: center; color: red;">您将打开支付页面，请问是否继续？<br><br>
          <span style="color: #b3b3b6; font-size: 15px; color: red;">注：支付成功后请关闭新打开的支付窗口</span>
        </div>
        <van-divider/>
        <div class="diy-dialog-buttons">
          <div>
            <el-button>
              <a @click="diyDialogShow = false;payBtnDisabled=false" style="font-size: 20px;">取消</a>
            </el-button>
          </div>
          <div>
            <el-button>
              <a target="_blank" :href="codeUrl" @click="diyDialogShow = false" style="font-size: 20px;">继续支付</a>
            </el-button>
          </div>
        </div>
      </div>
  
        <van-overlay :show="diyDialogShow"/> -->
  
      <!-- 微信支付二维码 -->
      <!-- <el-dialog
        :visible.sync="codeDialogVisible"
        :show-close="false"
        @close="closeDialog"
        width="350px"
        center
      >
        <qriously :value="codeUrl" :size="300" />
        使用微信扫码支付
      </el-dialog> -->
    </div>
  </template>
  
  <script>

import manageApi from '../api/manage'
import wxPayApi from '../api/wxPay'

  
  export default {
    data() {
      return {
        // appid: 'wx39d47973f29306cf',//公众号ID-测试的
        appid: 'wx0710d9e3f6746926',//开发者ID(AppID)
        // appsecret: '7ea4ebc68c90bf6042150aa6600b110f',--测试的
        appsecret: '3d4ad64be5e9c0a762db642d6a9f6924',//开发者密码(AppSecret)
      
        notices: [],//滚动消息
        currentIndex: 0,
        duration: 20, // 每条公告滚动的时间

        payBtnDisabled: false, //确认支付按钮是否禁用
        codeDialogVisible: false, //微信支付二维码弹窗
        gamePlateList: [], //游戏平台列表
        gameList: [], // 游戏列表
        payOrder: {
          //订单信息
          userName: '',
          payMoney: '',
          gamePlate: '',
          gameId: '',
          rechargeType: '2',
          payType: '', //支付方式
          tradeType: 'JSAPI',
          openId: ''
        },
        returnMap: {},
        codeUrl: '', // 二维码
        orderNo: '', //订单号
        timer: null, // 定时器
        diyDialogShow: false,
        // 校验规则
                  caseRules: {
                      userName: [{
                          required: true,
                          message: '账号不能为空',
                          trigger: 'blur'
                      }],
                      payMoney: [{
                          required: true,
                          message: '充值金额不能为空',
                          trigger: 'blur'
                      }],
            gamePlate: [{
                          required: true,
                          message: '游戏平台不能为空',
                          trigger: 'blur'
                      }],
            gameId: [{
                          required: true,
                          message: '游戏不能为空',
                          trigger: 'blur'
                      }]
                  }
      }
    },
  
    //页面加载时执行
    created() {
      //获取游戏平台列表
      manageApi.listGamePlate().then((response) => {
        this.gamePlateList = response.data.gamePlateList
      })
      let code= this.getUrlKey("code");
      console.log('code',code)
      if(code){
          this.getOpenId(code)
      }else{
          this.getCodeApi();
      }

      manageApi.listScrollMessage().then((res) => {
      for(let i=0; i< res.data.scrollMessageList.length; i++) {
        this.notices.push(res.data.scrollMessageList[i].scrollMessage)
      }
      console.log('notices',this.notices)
      // this.notices = res.data.scrollMessageList
		})
    // 启动定时器，每隔一段时间切换公告
    setInterval(this.moveToNextNotice, this.duration );
    },
  
    methods: {

      getCodeApi(){//获取code   
        let urlNow=encodeURIComponent(window.location.href);
        console.log('urlNow',urlNow)
        // let scope='snsapi_userinfo';    //snsapi_userinfo snsapi_base   //静默授权 用户无感知
        let url= 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.appid+'&redirect_uri='+urlNow+'&response_type=code&scope=snsapi_base#wechat_redirect';
        window.location.href = url
      },
      getUrlKey(name){//获取url 参数
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[,""])[1].replace(/\+/g,'%20'))||null;
      },

      getOpenId(code) {
        wxPayApi.getOpenId(code).then((res) => {
          this.payOrder.openId = res
        })
      },

      cancalReadOnly(onOff) {
          this.$nextTick(() => {
              if (!onOff) {
                  const {select} = this.$refs;
                  const input = select.$el.querySelector('.el-input__inner');
                  input.removeAttribute('readonly');
              }
          });
      },
      moveToNextNotice() {
      this.currentIndex++;
      if (this.currentIndex >= this.notices.length) {
        this.currentIndex = 0;
      }
    },
      queryGameByGamePlate() {
        if (this.payOrder.gamePlate === '') {
          this.$message({
                message: '请选择游戏平台',
                type: 'error',
                duration: 1000
            });
            return
        }
        manageApi.listGameByGamePlate({gamePlate:this.payOrder.gamePlate}).then((response) => {
          this.gameList = response.data.gameList
        })
      },
  
  
      //确认支付
      toPay() {
  
        if (this.payOrder.gamePlate === '' || this.payOrder.gamePlate.size === 0) {
            this.$message({
                message: '请选择游戏平台',
                type: 'error',
                duration: 1000
            });
            return
        }
  
        if (this.payOrder.gameId === '' || this.payOrder.gameId.size === 0) {
          this.$message({
                message: '请选择充值游戏',
                type: 'error',
                duration: 1000
            });
            return
        }
  
        this.payOrder.payType = 'wxpay'
        //禁用按钮，防止重复提交
        this.payBtnDisabled = true
        
          //调用统一下单接口
          wxPayApi.nativePayV2(this.payOrder).then((response) => {
            this.payBtnDisabled = false
            if (response.code === 200) {
            // 支持回调必要方法start
            if (typeof WeixinJSBridge == "undefined") {
                if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', function () { this.onBridgeReady(response.data.returnMap); }, false);
                } else if (document['attachEvent']) {
                    document['attachEvent']('WeixinJSBridgeReady', function () { this.onBridgeReady(response.data.returnMap); });
                    document['attachEvent']('onWeixinJSBridgeReady', function () { this.onBridgeReady(response.data.returnMap); });
                }
            } else {
              this.onBridgeReady(response.data.returnMap);
            }
              // //启动定时器
              // this.timer = setInterval(() => {
              //   //查询订单是否支付成功
              //   this.queryOrderStatus()
              // }, 2000)
            } else{
              this.$message({
              message: response.data.message,
              type: 'error',
              duration: 1000
              });
            }
          })
      },

      onBridgeReady(obj) {
            if (obj.appId == null || obj.appId == '') {
                return;
            }
            var timeStamp = obj.timeStamp;
            var nonceStr = obj.nonceStr;
            var packageId = obj.package;
            var paySign = obj.paySign;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                    "appId": obj.appId,     //公众号ID，由商户传入
                    "timeStamp": timeStamp,         //时间戳，自1970年以来的秒数
                    "nonceStr": nonceStr, //随机串
                    "package": packageId,
                    "signType": "MD5",         //微信签名方式：
                    "paySign": paySign //微信签名
                },
                function (res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                      alert('支付成功');
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      WeixinJSBridge.call('closeWindow');
                    } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
                      alert('支付取消');
                    } else {
                      alert(`支付失败：${res.err_msg}`);
                    }
                });
        },
  
      // 查询订单状态
      queryOrderStatus() {
        manageApi.queryOrderStatus(this.orderNo).then((response) => {
          console.log('查询订单状态：' + response.code)
          // 支付成功后的页面跳转
          if (response.code === 200) {
            this.$message({
              message: response.data.message,
              type: 'info',
              duration: 1000
            });
            console.log('清除定时器')
            clearInterval(this.timer)
            this.payBtnDisabled = false
            // 三秒后跳转到支付成功页面
           this.codeDialogVisible = false
          }
        })
      },
    },
  }
  </script>
  
  <style scoped>
      .title {
          color: #409eff;
          font: bold 28px/60px "microsoft yahei";
          width: 100%;
          text-align: center;
          margin-bottom: 25px;
      }
    .container {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
  }
  
  /* .diy-dialog {
      z-index: 9;
      top: 35%;
      background-color: rgb(114, 201, 178);
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 8px 1px 8px;
      text-align: left;
      font-size: 14px;
      width: 70%;
      border-radius: 10px;
      left: 14.5%;
  }
  .diy-dialog .diy-dialog-buttons {
      padding: 0 15px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      height: 60px;
  }
  .diy-dialog .diy-dialog-buttons a {
      color: #1783d6;
  }
  .diy-dialog .diy-dialog-buttons div {
      width: 100%;
      text-align: center;
  }
  .diy-dialog .van-divider--hairline {
      margin: 15px 0;
  } */

  
.notice-content {
  display: flex;
  white-space: nowrap;
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-top: -20px;
  margin-bottom: 10px;
}

.notice-item {
  flex-shrink: 0;
  font-size: 14px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
  </style>