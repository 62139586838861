<template>
  <div class="bg-fa of">
    <div class="title">
				自助充值中心
		</div>
    
    <section id="recharge" class="container">
      <div class="notice-content" :style="{ animationDuration: duration + 's' }">
        <div v-for="(item, index) in notices" :key="index" class="notice-item">
          {{ item }}
        </div>
      </div>
      <header class="comm-title">
        <h2 class="fl tac">
          <span class="c-333">充值信息：</span>
        </h2>
      </header>
      <el-form :model="payOrder" ref='rechargeRef' :rules="caseRules" label-width="100px">
				<el-form-item prop="userName" label="游戏账号 :" required>
					<el-input v-model.trim="payOrder.userName"  placeholder="请输入账号(7Li平台输入UID)" style="width: 80%"></el-input>
				</el-form-item>
        <el-form-item prop="payMoney" label="充值金额 :" required>
					<el-input v-model.trim="payOrder.payMoney" placeholder="请输入金额" style="width: 80%"></el-input>
				</el-form-item>
        <el-form-item prop="gamePlate" label="游戏平台 :" required>
          <el-select v-model="payOrder.gamePlate" placeholder="请选择游戏平台" style="width: 80%">
            <el-option
              v-for="item in gamePlateList"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
				</el-form-item>
        <el-form-item prop="gameId" label="充值游戏 :" required>
					<el-select v-model="payOrder.gameId" filterable placeholder="请选择充值游戏" style="width: 80%" @click.native="queryGameByGamePlate">
            <el-option
              v-for="item in gameList"
              :key="item.gameId"
              :label="item.gameName"
              :value="item.gameId">
            </el-option>
          </el-select>
				</el-form-item>
        <el-radio v-model="payOrder.rechargeType" label="1">首充</el-radio>
        <el-radio v-model="payOrder.rechargeType" label="2">续充</el-radio>
			</el-form>

      <div class="PaymentChannel_payment-channel-panel">
        <h3 class="PaymentChannel_title">选择支付方式</h3>
        <div class="PaymentChannel_channel-options">
          <!-- 选择微信 -->
          <div
            :class="[
              'ChannelOption_payment-channel-option',
              { current: payOrder.payType === 'wxpay' },
            ]"
            @click="selectPayType('wxpay')"
          >
            <div class="ChannelOption_channel-icon">
              <img src="../assets/img/wxpay.png" class="ChannelOption_icon" />
            </div>
            <div class="ChannelOption_channel-info">
              <div class="ChannelOption_channel-label">
                <div class="ChannelOption_label">微信支付</div>
                <div class="ChannelOption_sub-label"></div>
                <div class="ChannelOption_check-option"></div>
              </div>
            </div>
          </div>

          <!-- 选择支付宝 -->
          <div
            :class="[
              'ChannelOption_payment-channel-option',
              { current: payOrder.payType === 'alipay' },
            ]"
            @click="selectPayType('alipay')"
          >
            <div class="ChannelOption_channel-icon">
              <img src="../assets/img/alipay.png" class="ChannelOption_icon" />
            </div>
            <div class="ChannelOption_channel-info">
              <div class="ChannelOption_channel-label">
                <div class="ChannelOption_label">支付宝</div>
                <div class="ChannelOption_sub-label"></div>
                <div class="ChannelOption_check-option"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="payButtom">
        <el-button
          :disabled="payBtnDisabled"
          type="warning"
          round
          style="width: 200px; height: 44px; font-size: 18px"
          @click="toPay()"
        >
          确认支付
        </el-button>
      </div>
    </section>

    <!-- 微信支付二维码 -->
    <el-dialog
      :visible.sync="codeDialogVisible"
      :show-close="false"
      @close="closeDialog"
      width="350px"
      center
    >
      <qriously :value="codeUrl" :size="300" />
      使用微信扫码支付
    </el-dialog>
  </div>
</template>

<script>
import manageApi from '../api/manage'
import wxPayApi from '../api/wxPay'
import aliPayApi from '../api/aliPay'

export default {
  data() {
    return {
      notices: [],//滚动消息
      currentIndex: 0,
      duration: 20, // 每条公告滚动的时间

      payBtnDisabled: false, //确认支付按钮是否禁用
      codeDialogVisible: false, //微信支付二维码弹窗
      gamePlateList: [], //游戏平台列表
      gameList: [], // 游戏列表
      payOrder: {
        //订单信息
        userName: '',
        payMoney: '',
        gamePlate: '',
        gameId: '',
        rechargeType: '2',
        payType: '', //支付方式
        tradeType: 'NATIVE'
      },
      codeUrl: '', // 二维码
      orderNo: '', //订单号
      timer: null, // 定时器
      // 校验规则
				caseRules: {
					userName: [{
						required: true,
						message: '账号不能为空',
						trigger: 'blur'
					}],
					payMoney: [{
						required: true,
						message: '充值金额不能为空',
						trigger: 'blur'
					}],
          gamePlate: [{
						required: true,
						message: '游戏平台不能为空',
						trigger: 'blur'
					}],
          gameId: [{
						required: true,
						message: '游戏不能为空',
						trigger: 'blur'
					}]
				}
    }
  },

  //页面加载时执行
  created() {
    //获取游戏平台列表
    manageApi.listGamePlate().then((response) => {
      this.gamePlateList = response.data.gamePlateList
    })
    manageApi.listScrollMessage().then((res) => {
      for(let i=0; i< res.data.scrollMessageList.length; i++) {
        this.notices.push(res.data.scrollMessageList[i].scrollMessage)
      }
      console.log('notices',this.notices)
      // this.notices = res.data.scrollMessageList
		})
    // 启动定时器，每隔一段时间切换公告
    setInterval(this.moveToNextNotice, this.duration );
  },

  methods: {
    moveToNextNotice() {
      this.currentIndex++;
      if (this.currentIndex >= this.notices.length) {
        this.currentIndex = 0;
      }
    },
    queryGameByGamePlate() {
      if (this.payOrder.gamePlate === '') {
          this.$message({
                message: '请选择游戏平台',
                type: 'error',
                duration: 1000
            });
            return
        }
      manageApi.listGameByGamePlate({gamePlate:this.payOrder.gamePlate}).then((response) => {
        this.gameList = response.data.gameList
      })
    },

    //选择支付方式
    selectPayType(type) {
      console.log('支付方式：' + type)
      this.payOrder.payType = type
      //this.$router.push({ path: '/order' })
    },

    //确认支付
    toPay() {

      if (this.payOrder.gamePlate === '' || this.payOrder.gamePlate.size === 0) {
        this.$message({
						message: '请选择游戏平台',
						type: 'error',
						duration: 1000
					});
          return
      }

      if (this.payOrder.payType == '') {
        this.$message({
						message: '请选择支付方式',
						type: 'error',
						duration: 1000
					});
          return
      }

      if (this.payOrder.gameId === '' || this.payOrder.gameId.size === 0) {
        this.$message({
						message: '请选择充值游戏',
						type: 'error',
						duration: 1000
					});
          return
      }

      //禁用按钮，防止重复提交
      this.payBtnDisabled = true

      //微信支付
      if (this.payOrder.payType === 'wxpay') {
        
        //调用统一下单接口
        wxPayApi.nativePayV2(this.payOrder).then((response) => {
          this.payBtnDisabled = false
          if (response.code === 200) {
            this.codeUrl = response.data.codeUrl
            this.orderNo = response.data.orderNo
    
            //打开二维码弹窗
            this.codeDialogVisible = true

            //启动定时器
            this.timer = setInterval(() => {
              //查询订单是否支付成功
              this.queryOrderStatus()
            }, 2000)
          } else{
            this.$message({
						message: response.data.message,
						type: 'error',
						duration: 1000
						});
          }
        })

        //支付宝支付
      } else if (this.payOrder.payType === 'alipay') {

        //调用支付宝统一收单下单并支付页面接口
        aliPayApi.tradePagePay(this.payOrder).then((response) => {
          this.payBtnDisabled = false
          if (response.code === 200) {
            //将支付宝返回的表单字符串写在浏览器中，表单会自动触发submit提交
            document.write(response.data.formStr)
          } else{
            this.$message({
						message: response.data.message,
						type: 'error',
						duration: 3000
						});
          }
        })
      }
    },

    //关闭微信支付二维码对话框时让“确认支付”按钮可用
    closeDialog() {
      console.log('close.................')
      this.payBtnDisabled = false
      console.log('清除定时器')
      clearInterval(this.timer)
    },

    // 查询订单状态
    queryOrderStatus() {
      manageApi.queryOrderStatus(this.orderNo).then((response) => {
        console.log('查询订单状态：' + response.code)
        // 支付成功后的页面跳转
        if (response.code === 200) {
          this.$message({
          message: response.data.message,
          type: 'info',
          duration: 1000
				  });
          console.log('清除定时器')
          clearInterval(this.timer)
          this.payBtnDisabled = false
          // 三秒后跳转到支付成功页面
          this.codeDialogVisible = false
        }
      })
    },

    reset() {
      this.payOrder.payMoney='',
      this.payOrder.gamePlate='',
      this.payOrder.gameId='',
      this.payOrder.rechargeType='2',
      this.payOrder.payType=''
    }
  },
}
</script>

<style scoped>
	.title {
		color: #409eff;
		font: bold 28px/60px "microsoft yahei";
		width: 100%;
		text-align: center;
		margin-bottom: 25px;
	}
  .container {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.notice-content {
  display: flex;
  white-space: nowrap;
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-top: -10px;
  margin-bottom: 10px;
}

.notice-item {
  flex-shrink: 0;
  font-size: 14px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>