<template>
	<div class="red_package_rule_list">
		<el-card class="box-card">
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>新增</el-button>
			<!-- 列表页 -->
			<el-table :data="redPackageRuleList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="gameId" label="游戏ID" width="100">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="200">
				</el-table-column>
				<el-table-column prop="redPackageRule" label="活动规则" width="600">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" ref='createRef' class="createRedPackageRule">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">添加活动规则</div>
			</template>
			<el-form :model="newRedPackageRule" label-width="100px" size='mini' ref='createRef'>
				<el-form-item prop="gameId" label="红包雨游戏" required>
					<el-select v-model="newRedPackageRule.gameId" @change="selectChanged(newRedPackageRule.gameId)" filterable placeholder="请选择红包雨游戏" style="width: 80%" @click.native="getRedPackageGameList">
						<el-option
						v-for="item in redPackageGameList"
						:key="item.gameId"
						:label="item.gameName"
						:value="item.gameId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="活动规则内容" prop="redPackageRule">
					<textarea v-model="newRedPackageRule.redPackageRule" style="width: 400px; height: 250px;"></textarea>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>

		<!-- 编辑弹框 -->
		<el-dialog title="修改" :visible.sync="dialogEdit" class="updateRedPackageRule">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">修改活动规则内容</div>
			</template>
			<el-form :model="editRedPackageRule" label-width="80px" size='mini' :rules="caseRules" ref='updateRef'>
				<el-form-item prop="gameId" label="红包雨游戏">
					<el-select v-model="editRedPackageRule.gameId" placeholder="请选择红包雨游戏" autocomplete="off" :disabled="true">
						<el-option
						v-for="item in redPackageGameList"
						:key="item.gameId"
						:label="item.gameName"
						:value="item.gameId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="活动规则内容" prop="redPackageRule">
					<textarea v-model="editRedPackageRule.redPackageRule" style="width: 400px; height: 250px;"></textarea>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: -25px;">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				redPackageGameList: [],
				redPackageRuleList: [],
				//创建
				dialogCreate: false,
				dialogEdit: false,
				editRedPackageRule: {},
				newRedPackageRule: {
					redPackageRule: '',
					gameId: '',
					gameName: ''
				}
			}
		},
		methods: {
			//获取抽奖游戏列表
			getRedPackageGameList() {
				manageApi.getRedPackageGameList().then((response) => {
					this.redPackageGameList = response.data.redPackageGameList
				})
			},
			selectChanged(value) {
				for (let i = 0; i < this.redPackageGameList.length; i++) {
					if (this.redPackageGameList[i].gameId === value) {
						this.newRedPackageRule.gameName = this.redPackageGameList[i].gameName
					}
				}
			},
			close() {
				this.dialogCreate = false
				this.newRedPackageRule.redPackageRule = ''
			},
			create() {
				manageApi.addRedPackageRule(this.newRedPackageRule).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogCreate = false
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.editRedPackageRule = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updateRedPackageRule(this.editRedPackageRule).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			async proDelete(id) {
				console.log('当前删除的数据id为：', id)
				manageApi.deleteRedPackageRule(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.getRedPackageRuleList().then((res) => {
					if (res.code === 200) {
						this.redPackageRuleList = res.data.redPackageRuleList
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.createRedPackageRule {
	width: 80%;
	margin: 0 auto;
}
</style>