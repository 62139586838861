<template>
	<div class="game_user_list">
		<el-card class="box-card">
			<el-form :inline="true" :model="formData" ref='loginRef' @keydown.enter.native="searchGameUserHandle">
				<el-form-item prop="userName" label="玩家账号 :">
					<el-input v-model="formData.userName" placeholder="请输入玩家账号"></el-input>
				</el-form-item>
				<el-form-item prop="gameName" label="游戏名称 :">
					<el-input v-model="formData.gameName" placeholder="请输入游戏名称"></el-input>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="searchGameUserHandle">搜索</el-button>
					<el-button type="primary" @click="reset">重置</el-button>
				</el-form-item>
			</el-form>
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>新增</el-button>
			<!-- 列表页 -->
			<el-table :data="gameUserList" style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="userName" label="玩家账号" width="150">
				</el-table-column>
				<el-table-column prop="openId" label="微信OPENID" width="150">
				</el-table-column>
				<el-table-column prop="gameId" label="游戏ID" width="150">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="150">
				</el-table-column>
				<el-table-column prop="type" label="类型" width="150">
				</el-table-column>
				<el-table-column prop="num" label="金额或个数" width="150">
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 项目翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>

		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" :rules="caseRules" ref='createRef' class="createGameUser">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">新增内定玩家</div>
			</template>
			<el-form :model="redPackageUser" label-width="80px" size='mini' :rules="caseRules" ref='createRef'>
				<el-form-item label="玩家账号" prop="userName">
					<el-input v-model="redPackageUser.userName"></el-input>
				</el-form-item>
				<el-form-item label="微信OPENID" prop="openId">
					<el-input v-model="redPackageUser.openId"></el-input>
				</el-form-item>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="redPackageUser.gameId"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="redPackageUser.gameName"></el-input>
				</el-form-item>
				<el-form-item prop="type" label="类型">
					<el-select v-model="redPackageUser.type" placeholder="请选择类型">
						<el-option
						v-for="item in typeList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="金额或个数" prop="num">
					<el-input v-model="redPackageUser.num"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: -25px;">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>

		<!-- 编辑弹框 -->
		<el-dialog title="修改" :visible.sync="dialogEdit" class="updateGameUser">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">修改内定玩家</div>
			</template>
			<el-form :model="editRedPackageUser" label-width="80px" size='mini' :rules="caseRules" ref='updateRef'>
				<el-form-item label="玩家账号" prop="userName">
					<el-input v-model="editRedPackageUser.userName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="微信OPENID" prop="openId">
					<el-input v-model="editRedPackageUser.openId" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="editRedPackageUser.gameId" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="editRedPackageUser.gameName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item prop="type" label="类型">
					<el-select v-model="editRedPackageUser.type" placeholder="请选择类型" autocomplete="off">
						<el-option
						v-for="item in typeList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="金额或个数" prop="num">
					<el-input v-model="editRedPackageUser.num" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" style="margin-top: -25px;">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				typeList: ['平台币','红包'],
				gameUserList: [],
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				formData: {
					userName: '',
					gameName: ''
				},
				//创建
				dialogCreate: false,
				dialogEdit: false,
				editRedPackageUser: {},
				redPackageUser: {
					userName: '',
					openId: '',
					gameId: '',
					gameName: '',
					type: '',
					num: ''
				},
				// 校验规则
				caseRules: {
					gameId: [{
						required: true,
						message: '游戏ID不能为空',
						trigger: 'blur'
					}],
					gameName: [{
						required: true,
						message: '游戏名称不能为空',
						trigger: 'blur'
					}],
					userName: [{
						required: true,
						message: '玩家账号不能为空',
						trigger: 'blur'
					}],
					openId: [{
						required: true,
						message: '微信OPENID不能为空',
						trigger: 'blur'
					}]
				}
 
			}
		},
		methods: {
			reset(){
				this.formData.userName = ''
				this.formData.gameName = ''
			},
			close() {
				this.dialogCreate = false
				this.redPackageUser.gameId = ''
				this.redPackageUser.gameName = ''
				this.redPackageUser.type = ''
				this.redPackageUser.userName = ''
				this.redPackageUser.openId = ''
				this.redPackageUser.num = ''
			},
			searchGameUserHandle() {
				this.getList()
			},
			create() {
				manageApi.addRedPackageUser(this.redPackageUser).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogCreate = false
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.editRedPackageUser = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updateRedPackageUser(this.editRedPackageUser).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			async proDelete(id) {
				console.log('当前删除的数据id为：', id)
				manageApi.deleteRedPackageUser(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listRedPackageUser(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.gameUserList = res.data.redPackageUserPage.records
						// 保存数据总数
						this.count = res.data.redPackageUserPage.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.createGameUser {
	width: 60%;
	margin: 0 auto;
}
.updateGameUser {
	width: 60%;
	margin: 0 auto;
}
</style>