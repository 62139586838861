<template>
	<div class="login_box">
		<el-card class="box-card">
			<div class="title">
				后台管理系统
			</div>
			<el-form :model="formLogin" :rules="loginRules" ref='loginRef' @keydown.enter.native="loginHandle">
				<el-form-item prop="username">
					<el-input v-model="formLogin.username" prefix-icon="el-icon-user" placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input v-model="formLogin.password" type='password' prefix-icon="el-icon-lock"
						placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="loginHandle">点击登录</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
 
</template>
 
<script>
	import manageApi from "../api/manage"

	export default {
		data() {
			return {
				formLogin: {
					username: '',
					password: ''
				},
				loginRules: {
					username: [{
						required: true,
						message: '账号不能为空',
						trigger: 'blur'
					}],
					password: [{
							required: true,
							message: '密码不能为空',
							trigger: 'blur'
						}
					]
				}
			}
		},
		methods: {
			// 点击登录之后，处理登录的方法
			loginHandle() {
				if (this.formLogin.username.length === 0 || this.formLogin.password.length === 0) {
					this.$message.error("账号和密码不能为空");
					return
				}
				manageApi.login(this.formLogin).then((response) => {
					if (response && response.code === 200) {
						window.sessionStorage.setItem('token', response.data.token)
						this.$router.push('/orders')
					} else {
						this.$message.error(response.data.message);
					}
      			})
			}
		},
		// 组件中的数据挂载到模板中之后，会触发这个生命周期钩子函数
		mounted(){
			// 获取localStorage中的账号,设置到data中
			const username = window.localStorage.getItem('username')
			if(username){
				this.formLogin.username = username
			}
		}
	}
</script>
 
<style scoped>
	/**
	 * 在style中，添加scoped属性，表示css样式，只对当前组件生效。
	 */
	.login_box {
		width: 600px;
		height: 400px;
		margin: 200px auto;
	}
 
	.title {
		color: #409eff;
		font: bold 28px/60px "microsoft yahei";
		width: 100%;
		text-align: center;
		margin-bottom: 25px;
	}
</style>