<template>
	<div class="game_discount_list">

		<el-card class="box-card">
			<el-form :inline="true" :model="formData" ref='loginRef' @keydown.enter.native="searchHandle">
				<el-form-item prop="gameName" label="游戏名称 :">
					<el-input v-model="formData.gameName" placeholder="请输入游戏名称"></el-input>
				</el-form-item>
				<el-form-item style="text-align: center;">
					<el-button type="primary" @click="searchHandle">搜索</el-button>
				</el-form-item>
			</el-form>
			<el-button type="primary" icon='el-icon-plus' @click='dialogCreate=true'>新增</el-button>
			<!-- 列表页 -->
			<el-table :data="discountList" border style="width: 100%;margin-bottom: 10px;">
				<el-table-column prop="gameId" label="游戏ID" width="150">
				</el-table-column>
				<el-table-column prop="gameName" label="游戏名称" width="250">
				</el-table-column>
				<el-table-column prop="gamePlate" label="游戏平台" width="150">
				</el-table-column>
				<el-table-column prop="moneyRange" label="金额范围" width="150">
				</el-table-column>
				<el-table-column prop="type" label="充值类型" width="150">
				</el-table-column>
				<el-table-column prop="discount" label="折扣" width="150">
				</el-table-column>
				<el-table-column label="操作"  width="150">
					<template slot-scope="scope">
						<el-button size="mini" @click="proEdit(scope.row)">修改</el-button>
						<el-button size="mini" type="danger" @click="proDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 翻页管理 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="page" :page-sizes="[5, 10, 20, 30]" :page-size="size"
					layout="total, sizes, prev, pager, next, jumper" :total="count">
			</el-pagination>
		</el-card>
 
		<!-- 编辑弹框 -->
		<el-dialog title="修改游戏折扣" :visible.sync="dialogEdit" class="updateDiscount">
			<el-form :model="editDiscount" :rules="caseRules" ref='updateRef' label-width="80px">
				<el-form-item label="ID" prop="id" v-if="false">
					<el-input v-model="editDiscount.id" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏ID" prop="gameId">
					<el-input v-model="editDiscount.gameId" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏名称" prop="gameName">
					<el-input v-model="editDiscount.gameName" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="游戏平台" prop="gamePlate">
					<el-input v-model="editDiscount.gamePlate" autocomplete="off" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="金额范围" prop="moneyRange">
					<el-input v-model="editDiscount.moneyRange" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item prop="type" label="充值类型">
					<el-select v-model="editDiscount.type" placeholder="请选择充值类型">
						<el-option
						v-for="item in types"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="折扣" prop="discount">
					<el-input v-model="editDiscount.discount" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogEdit = false">取消</el-button>
				<el-button type="primary" @click="update">修改</el-button>
			</div>
		</el-dialog>
		<!-- 创建账号弹框 -->
		<el-dialog :visible.sync="dialogCreate" :rules="caseRules" ref='createRef' class="createDiscount">
			<template slot='title'>
				<div style="text-align: center;width: 100%;font-size: 24px;">新增游戏折扣</div>
			</template>
			<el-form :model="newDiscount" label-width="80px" size='mini' :rules="caseRules" ref='createRef'>
				<el-form-item prop="gamePlate" label="游戏平台" required>
					<el-select v-model="newDiscount.gamePlate" placeholder="请选择游戏平台" style="width: 80%">
						<el-option
						v-for="item in gamePlateList"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
        		<el-form-item prop="gameId" label="充值游戏" required>
					<el-select v-model="newDiscount.gameId" @change="selectChanged(newDiscount.gameId)" filterable placeholder="请选择充值游戏" style="width: 80%" @click.native="queryGameByGamePlate">
						<el-option
						v-for="item in gameList"
						:key="item.gameId"
						:label="item.gameName"
						:value="item.gameId">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="金额范围" prop="moneyRange">
					<el-input v-model="newDiscount.moneyRange"></el-input>
				</el-form-item>
				<el-form-item prop="type" label="充值类型">
					<el-select v-model="newDiscount.type" placeholder="请选择充值类型">
						<el-option
						v-for="item in types"
						:key="item"
						:label="item"
						:value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="折扣" prop="discount" style="width: 80%">
					<el-input v-model="newDiscount.discount" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="create">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
 
<script>
import manageApi from "../api/manage"
	export default {
		data() {
			return {
				discountList: [],
				types: ['首充','续充'],
				gamePlateList: [], //游戏平台列表
     	        gameList: [], // 游戏列表
				// 当前选择的页码
				page: 1,
				// 数据总数
				count: 0,
				//每页的数据量
				size: 10,
				//编辑窗口显示
				dialogEdit: false,
				formData: {
					gameName: ''
				},
				editDiscount: {},
				//创建
				dialogCreate: false,
				newDiscount: {
					gameId: '',
					gameName: '',
					gamePlate: '',
					moneyRange: '',
					type: '',
					discount: ''
				},
				// 校验规则
				caseRules: {
					gameId: [{
						required: true,
						message: '游戏ID不能为空',
						trigger: 'blur'
					}],
					gameName: [{
						required: true,
						message: '游戏名称不能为空',
						trigger: 'blur'
					}],
					gamePlate: [{
						required: true,
						message: '游戏平台不能为空',
						trigger: 'blur'
					}],
					moneyRange: [{
						required: true,
						message: '金额范围不能为空',
						trigger: 'blur'
					}],
					type: [{
						required: true,
						message: '充值类型不能为空',
						trigger: 'blur'
					}],
					discount: [{
						required: true,
						message: '折扣不能为空',
						trigger: 'blur'
					}]
				}
 
			}
		},
		 //页面加载时执行
		 created() {
			//获取游戏平台列表
			manageApi.listGamePlate().then((response) => {
			this.gamePlateList = response.data.gamePlateList
			})
		},
		methods: {
			close() {
				this.dialogCreate = false
				this.newDiscount.gameId = ''
				this.newDiscount.gameName = ''
				this.newDiscount.gamePlate = ''
				this.newDiscount.moneyRange = ''
				this.newDiscount.type = ''
				this.newDiscount.discount = ''
			},
			queryGameByGamePlate() {
				manageApi.listGameByGamePlate({gamePlate:this.newDiscount.gamePlate}).then((response) => {
					this.gameList = response.data.gameList
				})
			},

			selectChanged(value) {
				for (let i = 0; i < this.gameList.length; i++) {
					if (this.gameList[i].gameId === value) {
						this.newDiscount.gameName = this.gameList[i].gameName
					}
				}
			},
			searchHandle() {
				this.getList()
			},
			create() {
				manageApi.addGameDiscount(this.newDiscount).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.close()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			proEdit(value) {
				this.editDiscount = {
					...value
				}
				// 显示编辑框
				this.dialogEdit = true
			},
			async update() {
				manageApi.updateGameDiscount(this.editDiscount).then((res)=>{
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
						// 关闭弹框
						this.dialogEdit = false
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
 

			async proDelete(id) {
				console.log('当前删除的数据id为：', id)
				manageApi.deleteGameDiscount(id).then((res) => {
					if (res.code === 200) {
						this.$message({
							type: 'success',
							message: res.data.message,
							duration: 1000
						});
						// 更新页面的数据
						this.getList()
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 获取列表
			async getList() {
				manageApi.listGameDiscount(this.formData,this.size,this.page).then((res) => {
					if (res.code === 200) {
						this.discountList = res.data.discountPage.records
						// 保存数据总数
						this.count = res.data.discountPage.total
					} else {
						this.$message({
						message: res.data.message,
						type: 'error',
						duration: 1000
						});
					}
				})
			},
			// 处理每页数量大小变化的方法
			handleSizeChange(size) {
			this.size = size
			this.page = 1
			this.getList()
			},
			// 处理页码变化的方法
			handleCurrentChange(page) {
				this.page = page
				this.getList()
			}
		},
 
		// vue实例数据挂载之后，出触发这个钩子函数
		mounted() {
			this.getList()
		}
 
	}
</script>
 
<style scoped>
.updateDiscount {
	width: 60%;
	margin: 0 auto;
}
.createDiscount {
	width: 60%;
	margin: 0 auto;
}
</style>