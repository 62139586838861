// axios 发送ajax请求
import request from '@/utils/request'

export default{

  //发起支付请求
  tradePagePay(data) {
    return request({
      url: '/api/aliPay/trade/page/pay',
      method: 'post',
      data
    })
  }
}
