<template>
  <div class="rule">
    <div class="close" @click="closeHandle">x</div>
    <div class="wrap">
      <div class="title" style="color: rgb(216, 208, 44);">活动规则内容</div>
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>

<script>

import manageApi from '../api/manage'

export default {
  name: "rule",
  data() {
    return {
      content: ""
    };
  },
  methods: {
    closeHandle() {
      this.$router.push({
        name: "redPackageYu",
      });
    },
    getRedPackageRule(gameId) {
      this.dialogRule = true;
      manageApi.listRedPackageRule({gameId:gameId}).then((res)=>{
        if (res.data.redPackageRuleList !== null && res.data.redPackageRuleList.length>0) {
          var redPackageRuleList = res.data.redPackageRuleList
          console.log('res',redPackageRuleList)
          for(let i=0;i<redPackageRuleList.length;i++){
            this.content = this.content + redPackageRuleList[i] +'<br>';
          }
        } else {
          this.content = '暂无内容'
        }
      })
	},
    getContent() {
      this.content = `
            1.领取规则湿哒哒说的话说的<br>
            2.领取规则湿哒哒说的话说的<br>
            3.领取规则湿哒哒说的话说的<br>
            4.领取规则湿哒哒说的话说的<br>
            5.领取规则湿哒哒说的话说的<br>
        `;
    },
  },
  mounted() {
    var gameId = this.$route.params.gameId;
    this.getRedPackageRule(gameId);
  },
};
</script>

<style>
.record {
  width: 100wh;
  height: 100vh;
  background: url("../assets/img/bg2.png") no-repeat center;
  background-size: cover;
  position: relative;
}
.bg {
  width: 100vw;
  position: absolute;
}
.bg img {
  width: 100vw;
}
.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
  padding-top: 60px;
  flex-direction: column;
  z-index: 1111;
}
.close {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  font-weight: bolder;
}
.title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 600;
}
.content {
  width: 90vw;
  height: 60vh;
  /* background: rgba(000, 000, 000, 0.6); */
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  line-height: 30px;
  text-align: center;
}
</style>
