// 创建应用程序的路由器
import Vue from 'vue'
import VueRouter from 'vue-router'
// 此时就可以在Vue实例中配置路由器了
Vue.use(VueRouter)

// 引入组件
import Login from '../views/Login'
import Home from '../views/Home'
import Orders from '../views/Orders'
import Game from '../views/Game'
import UserDiscount from '../views/UserDiscount'
import GameDiscount from '../views/GameDiscount'
import AdminAccount from '../views/AdminAccount'
import Error from '../views/Error'
import recharge from '../views/recharge'
import rechargeMobile from '../views/rechargeMobile'
import PlatePassword from '../views/PlatePassword'
import ScrollMessage from '../views/ScrollMessage'
import wxPublicNum from '../views/wxPublicNum'
import awardLogin from '../views/AwardLogin'
import lotteryGame from '../views/LotteryGame'
import prizeGrantRecord from '../views/PrizeGrantRecord'
import rewardContent from '../views/RewardContent'
import award from '../views/Award'
import indexPage from '../views/indexPage'
import redPackageYu from '../views/RedPackageYu'
import redPackageBase from '../views/RedPackageBase'
import redPackageGame from '../views/RedPackageGame'
import redPackageRecord from '../views/RedPackageRecord'
import redPackageRule from '../views/RedPackageRule'
import redPackageUser from '../views/RedPackageUser'
import rule from '../views/rulePage'
import rank from '../views/Rank'

// 创建并暴露一个路由器
const router = new VueRouter({
    routes:[
        {
            path:'/',
            redirect:'/wxPublicNum'//默认显示
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {title:'后台管理系统'}
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            meta: {title:'后台管理系统'},
            redirect:'/orders',
            children: [
                {
                    path: '/orders',
                    name: 'order',
                    meta: {title:'后台管理系统'},
                    component: Orders
                },
                {
                    path: '/game',
                    name: 'game',
                    meta: {title:'后台管理系统'},
                    component: Game
                },
                {
                    path: '/userDiscount',
                    name: 'userDiscount',
                    meta: {title:'后台管理系统'},
                    component: UserDiscount
                },
                {
                    path: '/gameDiscount',
                    name: 'gameDiscount',
                    meta: {title:'后台管理系统'},
                    component: GameDiscount
                },
                {
                    path: '/adminAccount',
                    name: 'adminAccount',
                    meta: {title:'后台管理系统'},
                    component: AdminAccount
                },
                {
                    path: '/platePassword',
                    name: 'platePassword',
                    meta: {title:'后台管理系统'},
                    component: PlatePassword
                },
                {
                    path: '/scrollMessage',
                    name: 'scrollMessage',
                    meta: {title:'后台管理系统'},
                    component: ScrollMessage
                },
                {
                    path: '/lotteryGame',
                    name: 'lotteryGame',
                    meta: {title:'后台管理系统'},
                    component: lotteryGame
                },
                {
                    path: '/prizeGrantRecord',
                    name: 'prizeGrantRecord',
                    meta: {title:'后台管理系统'},
                    component: prizeGrantRecord
                },
                {
                    path: '/rewardContent',
                    name: 'rewardContent',
                    meta: {title:'后台管理系统'},
                    component: rewardContent
                },
                {
                    path: '/redPackageBase',
                    name: 'redPackageBase',
                    meta: {title:'后台管理系统'},
                    component: redPackageBase
                },
                {
                    path: '/redPackageGame',
                    name: 'redPackageGame',
                    meta: {title:'后台管理系统'},
                    component: redPackageGame
                },
                {
                    path: '/redPackageRecord',
                    name: 'redPackageRecord',
                    meta: {title:'后台管理系统'},
                    component: redPackageRecord
                },
                {
                    path: '/redPackageRule',
                    name: 'redPackageRule',
                    meta: {title:'后台管理系统'},
                    component: redPackageRule
                },
                {
                    path: '/redPackageUser',
                    name: 'redPackageUser',
                    meta: {title:'后台管理系统'},
                    component: redPackageUser
                }
            ]
        },
        {
            path: '/error',
            name: 'error',
            component: Error
        },
        {
            path: '/recharge',
            name: 'recharge',
            component: recharge,
            meta: {title:'充值系统'}
        },
        {
            path: '/rechargeMobile',
            name: 'rechargeMobile',
            component: rechargeMobile,
            meta: {title:'充值系统'}
        },
        {
            path: '/wxPublicNum',
            name: 'wxPublicNum',
            component: wxPublicNum,
            meta: {title:'充值系统'}
        },
        {
            path: '/awardLogin',
            name: 'awardLogin',
            component: awardLogin,
            meta: {title:'抽奖系统'}
        },
        {
            path: '/award',
            name: 'award',
            component: award,
            meta: {title:'抽奖系统'}
        },
        {
            path: '/indexPage',
            name: 'indexPage',
            component: indexPage,
            meta: {title:'福利主页'}
        },
        {
            path: '/redPackageYu',
            name: 'redPackageYu',
            component: redPackageYu,
            meta: {title:'红包雨'}
        },
        {
            path: '/rule',
            name: 'rule',
            component: rule,
            meta: {title:'红包雨'}
        },
        {
            path: '/rank',
            name: 'rank',
            component: rank,
            meta: {title:'榜单'}
        }
    ]
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'E兔玩系统';
	if(to.path=== '/login' || window.sessionStorage.getItem('token') 
       || to.path=== '/recharge'|| to.path=== '/rechargeMobile'
       || to.path=== '/wxPublicNum'|| to.path=== '/awardLogin'
       || to.path=== '/award' || to.path=== '/indexPage' 
       || to.path=== '/redPackageYu' || to.path=== '/redPackageRule' 
       || to.path=== '/redPackageUser' || to.path=== '/rule' || to.path=== '/rank') return next()
	return next('/login')
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router;
